/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponsiveLine, Serie } from '@nivo/line';
import { LAPTOP_MAX_SIZE, useWindowSize } from '@sendible/design-system';
import { makeNumbersReadFriendly } from '@sendible/frontendv2/src/utils';
import { getChartLeftMargin } from '@sendible/frontendv2/src/pages/Reports/helpers';
import { ChartContainer } from '../../index.styles';
import { Tooltip } from '../../../Audience/components/Tooltip';
import { formatMonthAndDay } from '../../../Audience/components/Chart';
import { useTikTokReportContext } from '../../../../context';

interface VideosViewsByDayChartProps {
  data: Serie[];
  isBlank?: boolean;
}

export const VideosViewsByDayChart = ({ data, isBlank }: VideosViewsByDayChartProps) => {
  const { userOffset } = useTikTokReportContext();
  const { width } = useWindowSize();

  const isSmallScreen = width < LAPTOP_MAX_SIZE;
  const maxLegends = 7;
  const isBigList = data[0].data.length > maxLegends;

  const getDateValue = (string: string) => {
    const dt = new Date(string);

    dt.setHours(dt.getHours() + userOffset / 60);

    return formatMonthAndDay(dt).replace(/(^|-)0+/g, '$1');
  };

  const mappedDataNumbers = data[0].data.map(({ y }) => y) as number[];

  const biggestValue = Math.max(...mappedDataNumbers);
  const smallestValue = Math.min(...mappedDataNumbers);
  const marginLeft = getChartLeftMargin(biggestValue, smallestValue);

  return (
    <ChartContainer>
      <ResponsiveLine
        data={data}
        margin={{
          top: isSmallScreen ? 50 : 20,
          right: isSmallScreen ? 24 : 120,
          bottom: 24,
          left: isBlank ? 28 : marginLeft,
        }}
        xScale={{
          type: 'point',
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: isBlank ? 200 : 'auto',
          clamp: true,
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisBottom={{
          tickValues: data[0].data.filter((_, index) => (isBigList ? index % maxLegends === 0 : true)).map(({ x }) => x),
          format: (e) => getDateValue(e),
        }}
        axisLeft={{
          format: (v) => makeNumbersReadFriendly(v),
        }}
        animate={false}
        enableGridX={false}
        enableGridY
        colors={['#000000']}
        lineWidth={isBigList ? 2 : 4}
        enablePoints={false}
        enableArea
        enableCrosshair={false}
        useMesh
        tooltip={Tooltip}
        legends={
          !isSmallScreen
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                },
              ]
            : [
                {
                  anchor: 'top-left',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: -32,
                  itemsSpacing: 2,
                  itemDirection: 'left-to-right',
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                },
              ]
        }
      />
    </ChartContainer>
  );
};
