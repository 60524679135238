/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import { Icon } from '@sendible/design-system/src';
import { capitalizeFirst, turnCamelCaseIntoReadableText } from '@sendible/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Caption, Container, DateInfo, IconBox, ProfileThumbBox, Thumb, Data, Profile, ProfileInfo, Top } from './index.styles';
import { DataItem } from './components/DataItem';
import { VideoFilterType } from '../..';

interface TikTokVideoCardProps {
  activeMetric: VideoFilterType;
  caption: string;
  displayName: string;
  engagement: TikTokVideoType['engagement'];
  impressions: TikTokVideoType['impressions'] | null;
  postedDate: string;
  profileAvatar: string;
  shareUrl: string;
  thumbnailUrl: string;
  userName: string;
  videoId: string;
  watchMetrics: TikTokVideoType['watchMetrics'];
}

const emptyImpressionData = {
  'Direct Message': 'video_impression_direct_message',
  Follow: 'video_impression_follow',
  'For You': 'video_impression_for_you',
  Others: 'video_impression_others',
  'Personal Profile': 'video_impression_personal_profile',
  Sound: 'video_impression_sound',
  Search: 'video_impression_search',
};

const emptyImpressionKeys = Object.keys(emptyImpressionData);

export const TikTokVideoCard = ({
  activeMetric,
  caption,
  displayName,
  engagement,
  impressions,
  postedDate,
  profileAvatar,
  shareUrl,
  thumbnailUrl,
  userName,
  videoId,
  watchMetrics,
}: TikTokVideoCardProps) => {
  const [showImage, setShowImage] = useState(true);
  const { t } = useTranslation('reports');

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const date = new Date(postedDate);
  const localePostedDate = date.toLocaleTimeString(navigator.language, options);

  let dataList = [];

  const turnKeyToSnakeCase = (key: string) => {
    return key
      .replace(/([A-Z])/g, '_$1')
      .replace(/\s+/, '')
      .toLowerCase();
  };

  if (activeMetric !== 'sources') {
    const dataObject = {
      engagement: Object.entries(engagement),
      watch_metrics: Object.entries(watchMetrics),
    };
    const selectedData = dataObject[activeMetric];

    dataList = selectedData.map((data, index) => (
      <DataItem
        key={data[0]}
        hideBorder={index === selectedData.length - 1}
        label={capitalizeFirst(turnCamelCaseIntoReadableText(data[0]).toLowerCase())}
        value={data[1]}
      />
    ));
  } else {
    const baseImpressions = impressions || [];
    const impressionsKeys = impressions ? impressions.map(({ impression }) => impression) : [];
    const missingKeys = emptyImpressionKeys
      .map((k) => {
        if (!impressionsKeys.includes(k)) {
          return { impression: k, percentage: '-' };
        }
      })
      .filter((o) => o);

    const selectedData = [...baseImpressions, ...missingKeys] as TikTokVideoImpressionType[];
    const sortedData = selectedData.sort((a, b) => (a.impression < b.impression ? -1 : a.impression > b.impression ? 1 : 0));

    dataList = sortedData.map((data, index) => (
      <DataItem
        key={data.impression}
        hideBorder={index === sortedData.length - 1}
        label={t(`video_impression${turnKeyToSnakeCase(data.impression)}`)}
        value={typeof data.percentage === 'number' ? `${data.percentage}%` : '-'}
      />
    ));
  }

  return (
    <Container>
      <Top>
        <DateInfo>{localePostedDate}</DateInfo>
        <Profile>
          <ProfileThumbBox>
            <IconBox $color="socialTiktok">
              <Icon
                color="brandLight"
                name="tiktok"
                useStroke={false}
              />
            </IconBox>
            <img
              alt=""
              src={profileAvatar}
            />
          </ProfileThumbBox>
          <ProfileInfo>
            <p>
              <b>{displayName}</b>
            </p>
            <p>{`@${userName}`}</p>
          </ProfileInfo>
        </Profile>
        <Caption>{caption}</Caption>
      </Top>
      <Thumb
        href={shareUrl}
        target="_blank"
        rel="noreferrer no"
      >
        <Icon name="play" />
        {showImage && thumbnailUrl && (
          <img
            src={thumbnailUrl}
            alt={videoId}
            onError={() => setShowImage(false)}
          />
        )}
      </Thumb>
      <Data>{dataList}</Data>
    </Container>
  );
};
