/* eslint-disable no-nested-ternary */
import { Heading, Spinner } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useGetTikTokPerformances } from '@sendible/frontendv2/src/models/reports';
import { InteractionsByDayChart } from './components/InteractionsByDayChart';
import { VideosViewsByDayChart } from './components/VideosViewsByDayChart';
import { BlurWrapper, ChartContainer, Container, Content, LoadingWrapper } from './index.styles';
import { tikTokPerformanceMockData } from './mockData';
import { useReportsContext } from '../../../context';
import { Warning } from '../Warning';
import { Summary } from '../Summary';

export const Performance = () => {
  const { isPrint } = useReportsContext();
  const { t } = useTranslation('reports');
  const { data, isLoading, isError, isRefetching } = useGetTikTokPerformances();

  const isGettingData = isLoading || isRefetching;

  const hasData = !isError && data;
  const hasEnoughVideoData = hasData && data.views.viewsByDay.length > 0;
  const hasEnoughInteractionData = hasData && data.interactions.interactionsByDay.length > 0;
  const viewsData = hasEnoughVideoData ? data.views : tikTokPerformanceMockData.views;
  const interactionsData = hasEnoughInteractionData ? data.interactions : tikTokPerformanceMockData.interactions;

  const isVideosBlank = viewsData.viewsByDay.map(({ views }) => views).reduce((a, b) => a + b) === 0;
  const isInteractionsBlank =
    interactionsData.interactionsByDay.map(({ comments, likes, shares }) => comments + likes + shares).reduce((a, b) => a + b) === 0;

  const { bestPerformingDay } = viewsData;
  const { bestEngagingDay } = interactionsData;

  const videosViewsData = viewsData?.viewsByDay.map(({ date, views }) => {
    return {
      x: date,
      y: views,
      key: `video-views-${date}`,
    };
  });

  const videosViewsSummaryData = [
    {
      label: t('date', { ns: 'common_terms' }),
      value: bestPerformingDay?.date || '-',
    },
    {
      label: t('total_views'),
      value: bestPerformingDay?.current || '-',
    },
    {
      label: t('previous_period'),
      value: bestPerformingDay?.previous || '-',
    },
    {
      label: t('growth'),
      value:
        bestPerformingDay?.difference && bestPerformingDay?.percentage
          ? `${bestPerformingDay.difference} (${bestPerformingDay.percentage}%)`
          : bestPerformingDay?.difference
          ? bestPerformingDay?.difference
          : '-',
    },
  ];

  const interactionsSummaryData = [
    {
      label: t('date', { ns: 'common_terms' }),
      value: bestEngagingDay?.date || '-',
    },
    {
      label: t('total_engagement'),
      value: bestEngagingDay?.current || '-',
    },
    {
      label: t('previous_period'),
      value: bestEngagingDay?.previous || '-',
    },
    {
      label: t('growth'),
      value:
        bestEngagingDay?.difference && bestEngagingDay?.percentage
          ? `${bestEngagingDay.difference} (${bestEngagingDay.percentage}%)`
          : bestEngagingDay?.difference
          ? bestEngagingDay?.difference
          : '-',
    },
  ];

  const loadingContent = (
    <ChartContainer>
      <LoadingWrapper>
        <Spinner size="lg" />
      </LoadingWrapper>
    </ChartContainer>
  );

  const errorContent = (
    <Warning
      text={t('generic_report_error')}
      type="danger"
      color="semanticDangerShade20"
    />
  );

  const emptyContent = (
    <Warning
      text={t('nothing_here_yet')}
      type="info"
      color="semanticInfo"
    />
  );

  return (
    <Container $isPrint={isPrint}>
      {viewsData && videosViewsData && (
        <Content $isPrint={isPrint}>
          <Heading
            variation="component-title_14"
            semantic="h4"
          >
            {t('performance_chart1_title')}
          </Heading>
          {isGettingData ? (
            loadingContent
          ) : (
            <BlurWrapper $isBlurry={!hasEnoughVideoData}>
              {isError ? errorContent : !hasEnoughVideoData ? emptyContent : null}
              <ChartContainer>
                <VideosViewsByDayChart
                  data={[
                    {
                      id: 'Video views',
                      data: videosViewsData,
                    },
                  ]}
                  isBlank={isVideosBlank}
                />
              </ChartContainer>
              <Summary
                title={t('performance_best_performing_day')}
                items={videosViewsSummaryData}
              />
            </BlurWrapper>
          )}
        </Content>
      )}
      {interactionsData && (
        <Content $isPrint={isPrint}>
          <Heading
            variation="component-title_14"
            semantic="h4"
          >
            {t('performance_chart2_title')}
          </Heading>
          {isGettingData ? (
            loadingContent
          ) : (
            <BlurWrapper $isBlurry={!hasEnoughInteractionData}>
              {isError ? errorContent : !hasEnoughInteractionData ? emptyContent : null}
              <ChartContainer>
                <InteractionsByDayChart
                  data={interactionsData.interactionsByDay}
                  indexBy="date"
                  isBlank={isInteractionsBlank}
                />
              </ChartContainer>
              <Summary
                title={t('performance_best_engaging_day')}
                items={interactionsSummaryData}
              />
            </BlurWrapper>
          )}
        </Content>
      )}
    </Container>
  );
};
