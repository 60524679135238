import { errorUtils } from '@sendible/common';
import { Trans, Translation } from 'react-i18next';
import { Button, Heading, Icon, Text } from '@sendible/design-system';
import { Component, ReactNode } from 'react';
import errorBoundaryData from './errorBoundaryData';
import { ErrorBoundaryStyled } from './errorBoundary.styles';
import { ContentStyled } from '../components/ContentSection/index.styles';
import { LightboxStyled } from '../components/Preview/index.styles';

interface Props {
  errorSection: 'mediaLibrarySection' | 'contentSection' | 'previewMediasSection';
  isWhiteLabel: boolean;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: DLAPIErrorType;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.onTryAgain = this.onTryAgain.bind(this);
  }

  public static getDerivedStateFromError(error: Error): State {
    let apiError;

    try {
      apiError = JSON.parse(error?.message)?.error;
    } catch (e) {
      apiError = error;
      errorUtils.sendErrorDirectlyToOnerror(e as string);
    }

    return {
      hasError: true,
      error: apiError,
    };
  }

  public componentDidCatch(error: Error) {
    errorUtils.sendErrorDirectlyToOnerror(error.message);
    window.dispatchEvent(new CustomEvent('reportErrorToFaro', { detail: { error } }));
  }

  onTryAgain() {
    this.setState({
      hasError: false,
    });
    window.location.reload();
  }

  public render() {
    let supportMessage;
    let fullMessage;

    const { headingText, buttonText } = errorBoundaryData[this.props.errorSection];

    if (this.props.isWhiteLabel) {
      supportMessage = (
        <Translation ns="media_library">
          {(t) => (
            <>
              {t('error_if_persists_wl')}
              <br /> <br />
              <Button
                variation="fill"
                onClick={this.onTryAgain}
                label={t(buttonText) || ''}
              />
            </>
          )}
        </Translation>
      );
    } else {
      supportMessage = (
        <Translation ns="media_library">
          {(t) => (
            <>
              <Text>
                {t('error_if_persists')}{' '}
                <a
                  href="https://support.sendible.com/hc/en-us/requests/new"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('contact_support')}
                </a>
              </Text>
              <br /> <br />
              <Button
                variation="fill"
                onClick={this.onTryAgain}
                label={t(buttonText) || ''}
              />
            </>
          )}
        </Translation>
      );
    }

    if (this.state.hasError) {
      fullMessage = (
        <ErrorBoundaryStyled
          contentAlignment="stretch"
          contentJustify="center"
          orientation="column"
          verticalGap="s16"
          data-testid="error-message"
        >
          <Icon
            className="error_boundary-icon-danger"
            color="brandDarkAlpha50"
            name="danger"
          />
          <Heading
            align="left"
            color="brandDark"
            semantic="h2"
            variation="layout-title_28"
          >
            <Trans
              i18nKey={headingText}
              ns="media_library"
            />
          </Heading>
          <Text
            align="left"
            color="brandDark"
            variation="body_14"
          >
            <br />
            {supportMessage}
          </Text>
          {this.state.error?.message && (
            <Text
              className="error_boundary-error-text"
              color="brandDarkAlpha70"
              variation="body_12"
            >
              <Trans
                i18nKey="error_type"
                ns="media_library"
              />
              : {this.state.error?.message}
            </Text>
          )}
        </ErrorBoundaryStyled>
      );

      if (this.props.errorSection === 'contentSection') {
        return <ContentStyled>{fullMessage}</ContentStyled>;
      }

      if (this.props.errorSection === 'previewMediasSection') {
        return <LightboxStyled>{fullMessage}</LightboxStyled>;
      }

      return fullMessage;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
