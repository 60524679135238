/* eslint-disable @typescript-eslint/no-explicit-any */
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { LAPTOP_MAX_SIZE, useWindowSize } from '@sendible/design-system';
import { getLuminance } from 'polished';
import { capitalizeFirst } from '@sendible/common';
import { makeNumbersReadFriendly } from '@sendible/frontendv2/src/utils';
import { getChartLeftMargin } from '@sendible/frontendv2/src/pages/Reports/helpers';
import { formatMonthAndDay } from '../../../Audience/components/Chart';
import { useTikTokReportContext } from '../../../../context';

interface InteractionsByDayProps {
  data: BarDatum[];
  indexBy: 'date';
  isBlank?: boolean;
}

export const InteractionsByDayChart = ({ data, indexBy, isBlank }: InteractionsByDayProps) => {
  const { width } = useWindowSize();
  const { userOffset } = useTikTokReportContext();

  const isSmallScreen = width < LAPTOP_MAX_SIZE;
  const maxLegends = 7;
  const isBigList = data.length > maxLegends;

  const getDateValue = (string: string) => {
    const dt = new Date(string);

    dt.setHours(dt.getHours() + userOffset / 60);

    return formatMonthAndDay(dt).replace(/(^|-)0+/g, '$1');
  };
  // need to dynamically calculate left margin of the chart due to tha fact that labels width can change depending on the values
  const mappedDataNumbers = data.map((d: any) => (d.shares + d.comments + d.likes) as number).flat();

  const biggestValue = Math.max(...mappedDataNumbers);
  const smallestValue = Math.min(...mappedDataNumbers);

  const marginLeft = getChartLeftMargin(biggestValue, smallestValue);

  return (
    <ResponsiveBar
      data={data}
      keys={['likes', 'comments', 'shares']}
      indexBy={indexBy}
      margin={{
        top: isSmallScreen ? 50 : 20,
        right: isSmallScreen ? 24 : 120,
        bottom: 24,
        left: isBlank ? 28 : marginLeft,
      }}
      minValue={isBlank ? 0 : 'auto'}
      maxValue={isBlank ? 200 : 'auto'}
      padding={isBigList ? 0.1 : 0.3}
      valueFormat={(v) => makeNumbersReadFriendly(v, 2)}
      colors={(bar) => {
        if (bar.id === 'likes') return '#000';
        if (bar.id === 'comments') return '#0075DB';
        if (bar.id === 'shares') return '#E10543';

        return '#CCC';
      }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        tickValues: data.filter((_, index) => (isBigList ? index % maxLegends === 0 : true)).map(({ date }) => date),
        format: (e) => getDateValue(e),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        format: (v) => makeNumbersReadFriendly(v),
      }}
      tooltipLabel={(e) => getDateValue(e.indexValue as string)}
      enableLabel={!isBigList}
      animate={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={(bar) => (getLuminance(bar.color) > 0.5 ? '#000000' : '#FFFFFF')}
      legendLabel={(e) => capitalizeFirst(String(e.id))}
      legends={
        !isSmallScreen
          ? [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]
          : [
              {
                dataFrom: 'keys',
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -32,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]
      }
    />
  );
};
