/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeFaro, ErrorsInstrumentation } from '@grafana/faro-web-sdk';

interface HandleSendErrorArgsType {
  errorMessage?: string | Event;
  error?: Error;
  data?: any;
}

const transformStringToId = (string: string) => {
  return string
    .toLowerCase()
    .replace(/\s\s+/g, '-')
    .replace(/\s/g, '-')
    .replace(/[0-9]/g, '')
    .replace(/[\\\][<>,./?!@#$%^&*)(=+{}|:;'"`~]/g, '');
};

const setupFaroErrorReporting = (userId: string) => {
  if (process.env.SENDIBLE_ENV === 'production') {
    const faroUrl = `https://grafana-alloy.prod.sendible.com/collect`;
    const faroAppName = `frontend-faro`;

    const faro = initializeFaro({
      url: faroUrl,
      apiKey: 'glsa_qP6At1xfSP5mZwXBVjRRWE6KAVP01E01_e8313f77',
      app: {
        name: faroAppName,
        version: '1.0.0',
      },
      user: {
        id: userId,
      },
      instrumentations: [new ErrorsInstrumentation()],
      trackResources: false,
      consoleInstrumentation: {
        consoleErrorAsLog: true,
      },
      dedupe: true,
      beforeSend: (item) => {
        if (!item.meta.session) {
          item.meta.session = {
            attributes: {
              dashboard_version: String(window.dashboard_version) || '0',
            },
          };
        } else {
          item.meta.session = {
            ...item.meta.session,
            attributes: {
              ...item.meta.session.attributes,
              dashboard_version: String(window.dashboard_version) || '0',
            },
          };
        }

        if (item.payload) {
          const { payload, meta } = item as any;

          const valueToId = transformStringToId(payload.value);
          const urlToId = meta.page.url.replace('https://app.sendible.com/', '').split('/')[0];
          const id = `${valueToId}-${urlToId}`;

          item.meta.session = {
            ...item.meta.session,
            attributes: {
              ...item.meta.session.attributes,
              exception_definition_id: id,
            },
          };
        }

        return item;
      },
    });

    const handleSendErrorToFaro = ({ error, errorMessage, data }: HandleSendErrorArgsType) => {
      const obj: unknown[] = [];

      if (errorMessage) obj.push(errorMessage);
      if (error?.stack && errorMessage !== error?.stack) obj.push(error.stack);

      const stringifiedErrors = Array.from(new Set(obj));

      const config = data
        ? {
            context: {
              data,
            },
          }
        : {};

      faro.api.pushError(new Error(stringifiedErrors.reverse().join(', ')), config);
    };

    window.addEventListener(
      'reportErrorToFaro',
      function ({ detail }: any) {
        if (!detail) return;

        const data: HandleSendErrorArgsType = {
          error: detail.error,
          errorMessage: detail.error?.message || undefined,
          data: detail.data || undefined,
        };

        handleSendErrorToFaro(data);
      },
      { capture: true, once: false, passive: false }
    );

    window.onerror = function (errorMessage, fileName, lineNumber, column, error) {
      if (typeof error === 'undefined') {
        return true;
      }

      handleSendErrorToFaro({ errorMessage, error });
    };

    window.onunhandledrejection = function (error) {
      if (error.reason) handleSendErrorToFaro({ errorMessage: error.reason, error: undefined });
    };
  }
};

export default setupFaroErrorReporting;
