import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useGetTikTokProfiles } from '@sendible/frontendv2/src/models/reports';
import { pages } from '@sendible/frontendv2/src/routes';

type TikTokReportContextType = {
  date: TikTokReportDate;
  setDate: Dispatch<SetStateAction<TikTokReportDate>>;
  endDate: string | undefined;
  previousEndDate: Date;
  startDate: string | undefined;
  previousStartDate: Date;
  currentAccountObject?: TikTokAccountType;
  userOffset: number;
};

export const TikTokReportContext = createContext({} as TikTokReportContextType);

export const TikTokReportProvider = ({ children }: Component) => {
  const [sharedState] = useBridgeContext();

  const [date, setDate] = useState<TikTokReportDate>({ text: 'Last 7 days', altText: 'vs Previous 7 days', action: 'last7Days' });
  const navigate = useNavigate();
  const { id: paramIdString } = useParams();
  const accountId = paramIdString ? parseInt(paramIdString, 10) : null;
  const { data } = useGetTikTokProfiles();
  const currentAccountObject = data?.accounts.find((account) => account.id === accountId);
  const userOffset = sharedState.user.timezone;

  useEffect(() => {
    if (data?.accounts && data.accounts.length) {
      let accountObject = data.accounts.find((account) => accountId && account.id === accountId);

      if (!accountObject) {
        [accountObject] = data.accounts;
        navigate(`${pages.Measure.tiktok}/${accountObject.id}`);
      }
    } else {
      navigate(`${pages.Measure.tiktok}`);
    }
  }, [data?.accounts, paramIdString]);

  const yesterday = new Date();
  const oneWeekAgo = new Date();
  const oneMonthAgo = new Date();
  const twoMonthsAgo = new Date();

  yesterday.setDate(yesterday.getDate() - 1);
  oneWeekAgo.setDate(yesterday.getDate() - 7);
  oneMonthAgo.setDate(yesterday.getDate() - 30);
  twoMonthsAgo.setDate(yesterday.getDate() - 60);

  const dates: Record<TikTokReportDateAction, string> = {
    last7Days: oneWeekAgo.toISOString().substring(0, 10),
    last30Days: oneMonthAgo.toISOString().substring(0, 10),
    last60Days: twoMonthsAgo.toISOString().substring(0, 10),
  };

  const startDate = dates[date.action];
  const endDate = yesterday.toISOString().substring(0, 10);

  const previousEndDate = new Date(endDate as string);
  const previousStartDate = new Date(startDate as string);

  if (date.action.includes('7')) {
    previousEndDate.setDate(previousEndDate.getDate() - 8);
    previousStartDate.setDate(previousEndDate.getDate() - 7);
  } else if (date.action.includes('30')) {
    previousEndDate.setDate(previousEndDate.getDate() - 31);
    previousStartDate.setDate(previousEndDate.getDate() - 30);
  } else if (date.action.includes('60')) {
    previousEndDate.setDate(previousEndDate.getDate() - 61);
    previousStartDate.setDate(previousEndDate.getDate() - 60);
  }

  return (
    <TikTokReportContext.Provider
      value={{
        currentAccountObject,
        date,
        endDate,
        previousEndDate,
        previousStartDate,
        setDate,
        startDate,
        userOffset,
      }}
    >
      {children}
    </TikTokReportContext.Provider>
  );
};

export const useTikTokReportContext = (): TikTokReportContextType => useContext(TikTokReportContext);
