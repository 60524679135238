import { Point } from '@nivo/line';
import { formatMonthAndDay } from './Chart';
import { useTikTokReportContext } from '../../../context';

export const Tooltip = ({ point }: { point: Point }) => {
  const { userOffset } = useTikTokReportContext();
  const dt = new Date(point.data.x);

  dt.setHours(dt.getHours() + userOffset / 60);

  const formattedDate = formatMonthAndDay(dt);

  return (
    <div
      style={{
        padding: '0.5rem',
        background: 'white',
        border: '1px solid #ccc',
        textAlign: 'left',
        fontSize: '.75rem',
        borderRadius: '.25rem',
      }}
    >
      <strong>{point.serieId}</strong>
      <div>Date: {formattedDate}</div>
      <div>Value: {point.data.y.toLocaleString()}</div>
    </div>
  );
};
