import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, Icon, Spinner, Text } from '@sendible/design-system/src';
import { EVENTS, publish } from '@sendible/event-manager';
import { useGetTikTokProfiles } from '@sendible/frontendv2/src/models/reports';
import { ErrorBoundary } from '@sendible/frontendv2/src/components';
import {
  ReportContainer,
  ReportMessageContainer,
  NoReportsContainer,
  NoReportsErrorContainer,
  ReportLoadingOverlay,
  Sections,
  Section,
  SectionHeader,
} from './index.styles';
import { Header, Audience, Overview, Publishing, Performance, Videos, Demographics } from './components';
import { useTikTokReportContext } from './context';
import { useReportsContext } from '../context';

const TikTokReport = () => {
  const { isPrint } = useReportsContext();
  const { data, isLoading } = useGetTikTokProfiles();
  const { t } = useTranslation('reports');
  const { currentAccountObject } = useTikTokReportContext();

  if (isLoading) {
    return (
      <ReportMessageContainer>
        <Spinner size="lg" />
      </ReportMessageContainer>
    );
  }
  if (!data?.accounts.length) {
    return (
      <NoReportsContainer>
        <Heading semantic="h1">{t('tiktok_report')}</Heading>
        <NoReportsErrorContainer>
          <Icon
            name="tiktok_color"
            color=""
          />
          <Heading semantic="h4">{t('no_reports_available')}</Heading>
          <Button
            onClick={() => {
              publish(EVENTS.CHANGE_PAGE, '#services');
            }}
            label={t('tiktok_add_profile') as string}
            size={14}
            appearance="primary"
            variation="fill"
          />
        </NoReportsErrorContainer>
      </NoReportsContainer>
    );
  }

  return (
    <>
      {isPrint && (
        <ReportLoadingOverlay>
          <Spinner size="lg" />
        </ReportLoadingOverlay>
      )}
      <ReportContainer className={`v2-report-container ${isPrint ? 'print' : ''}`}>
        <Header />
        <ErrorBoundary
          key={currentAccountObject?.id}
          translationNamespace="reports"
        >
          <Sections>
            <Section>
              <Overview />
            </Section>

            <Section>
              <SectionHeader>
                <Heading
                  semantic="h2"
                  variation="layout-title_28"
                >
                  {t('audience_header')}
                </Heading>
              </SectionHeader>
              <Audience />
            </Section>

            <Section>
              <SectionHeader>
                <Heading
                  semantic="h2"
                  variation="layout-title_28"
                >
                  {t('publishing')}
                </Heading>
              </SectionHeader>
              <Publishing />
            </Section>

            <Section>
              <SectionHeader>
                <Heading
                  semantic="h2"
                  variation="layout-title_28"
                >
                  {t('performance_title')}
                </Heading>
              </SectionHeader>
              <Performance />
            </Section>

            <Section>
              <SectionHeader>
                <Heading
                  semantic="h2"
                  variation="layout-title_28"
                >
                  {t('videos_title')}
                </Heading>
              </SectionHeader>
              <Videos />
            </Section>

            <Section>
              <SectionHeader>
                <Box
                  horizontalGap="s12"
                  contentAlignment="center"
                >
                  <Heading
                    semantic="h2"
                    variation="layout-title_28"
                  >
                    {t('demographics_header')}
                  </Heading>
                  <Icon name="info" />
                  <Text>{t('lifetime_data')}</Text>
                </Box>
              </SectionHeader>
              <Demographics />
            </Section>
          </Sections>
        </ErrorBoundary>
      </ReportContainer>
    </>
  );
};

export default TikTokReport;
