// XLocalized strings for use in Javascript only. For HTML localization, use Utils.localize
// Exmples:
// dictionary.templates[term]
// Localized strings for use in Javascript only. For HTML localization, use Utils.localize
// Exmples:
// dictionary.templates[term]
export default {
  availableLanguages: ['en', 'sv', 'zh', 'de', 'es', 'fr', 'nl', 'cs', 'ja', 'it'],
  // Message Dialogs
  common: {
    daysOfWeek: {
      en: 'Monday Tuesday Wednesday Thursday Friday Saturday Sunday',
      es: 'Lunes Martes Miercoles Jueves Viernes Sabado Domingo',
      de: 'Montag Dienstag Mittwoch Donnerstag Freitag Samstag Sonntag',
      fr: 'lundi Mardi Mercredi Jeudi Vendredi Samedi Dimanche',
      it: 'lunedì martedì mercoledì giovedì venerdì sabato domenica',
      zh: '星期一 星期二 星期三 星期四 星期五 星期六 星期天',
      ja: '月曜日 火曜日 水曜日 木曜日 金曜日 土曜日 日曜日',
    },
    dayNamesShort: {
      en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      es: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    },
    months: {
      en: 'January February March April May June July August September October November December',
      de: 'Januar Februar März April Mai Juni Juli August September Oktober November Dezember',
      es: 'Enero Febrero Marzo Abril Mayo Junio Julio Agosto Septiembre Octubre Noviembre Diciembre',
      fr: 'Janvier Fevrier Mars Avril Mai Juin Juillet Aout Septembre Octobre Novembre Decembre',
      it: 'Gennaio Febbraio Marzo Aprile Maggio Giugno Luglio Agosto Settembre Ottobre Novembre Dicembre',
      cs: 'leden‎ únor‎ březen‎ duben‎ květen červen‎ červenec‎ srpen‎ září‎ říjen‎ listopad prosinec‎',
      sv: 'januari februari mars april maj juni juli augusti september oktober november december',
      tr: 'Ocak Şubat Mart Nisan Mayıs Haziran Temmuz Ağustos Eylül Ekim Kasım Aralık',
      nl: 'januari februari maart april mei juni juli augustus september oktober november december',
      ja: '1月 2月 3月 4月 5月 6月 7月 8月 9月 10月 11月 12月',
    },
    monthNamesShort: {
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      es: ['Enero', 'Feb', 'Mar', 'Abr.', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      it: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      de: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      nl: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      cs: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      sv: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      tr: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      ja: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    },
    currency: {
      usd: '$',
      eur: '&euro;',
      gbp: '&pound',
    },
  },
  templates: {
    // the default language is English so there are no translations here
    // translation files are loaded if the language is set to anything
    // other than English
    // (see the libs/localize folder for template translations in other languages)
  },
};
