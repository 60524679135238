import { useEffect, useMemo, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

export const useContainerDimensions = (
  containerRef: React.RefObject<HTMLDivElement>,
  smallScreenWidth: number,
  widthReductionNumber: number,
  heightReductionNumber: number
) => {
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const updateDimensions = () => {
      if (!containerRef.current) return;

      window.requestAnimationFrame(() => {
        const { offsetWidth: containerWidth } = containerRef.current as HTMLDivElement;
        const { offsetWidth: documentWidth } = document.documentElement;
        const isSmallScreenVar = documentWidth < smallScreenWidth;

        const initialWidth = containerWidth;
        const reducedWidth = Math.floor(containerWidth * widthReductionNumber);

        const initialHeight = Math.floor(containerWidth * heightReductionNumber);
        const reducedHeight = Math.floor(containerWidth * widthReductionNumber * heightReductionNumber);

        const width = isSmallScreenVar ? initialWidth : reducedWidth;
        const height = isSmallScreenVar ? initialHeight : reducedHeight;

        setDimensions({
          width,
          height,
        });
        setIsSmallScreen(isSmallScreenVar);
      });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, [containerRef, smallScreenWidth]);

  return { dimensions, isSmallScreen };
};

export const useFormattedDates = (dateRange: ReportDateRange) => {
  return useMemo(() => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };

    return {
      startDateFriendly: dateRange.startDate.toLocaleDateString(navigator.language, options),
      endDateFriendly: dateRange.endDate.toLocaleDateString(navigator.language, options),
    };
  }, [dateRange]);
};
