export default {
  cancel: 'Cancel',
  canva: 'Canva',
  content_sidebar_media_library: 'Media library',
  content_sidebar_feeds: 'RSS feeds',
  content_sidebar_content: 'My content',
  date: 'Date',
  documents: 'Documents',
  dropbox: 'Dropbox',
  drop_files: 'Drop your files here',
  error_type: 'Error',
  flickr: 'Flickr',
  friday: 'Friday',
  from_url: 'From URL',
  giphy: 'Giphy',
  google_drive: 'Google Drive',
  header_word_content: 'Content',
  header_word_publish: 'Publish',
  images: 'Images',
  monday: 'Monday',
  of: 'of',
  only_supported_by: 'Only supported by',
  or_drop_files: 'or drop your files here',
  page: 'page',
  pages: 'pages',
  pdf_linkedin: 'PDF - for LinkedIn only',
  pexels: 'Pexels',
  posts_comments: 'Comments',
  posts_sent: 'Posts sent',
  posts_likes: 'Likes',
  posts_interactions: 'Interactions',
  profile_audience: 'Audience',
  publish_sidebar_link_calendar: 'Calendar',
  publish_sidebar_link_campaigns: 'Campaigns',
  publish_sidebar_link_scheduled: 'Scheduled',
  publish_sidebar_link_queues: 'Queues',
  publish_sidebar_link_sent: 'Sent',
  publish_sidebar_link_undelivered: 'Undelivered',
  publish_sidebar_link_drafts: 'Drafts',
  publish_sidebar_link_tasks: 'Tasks',
  replace: 'Replace',
  recently_used: 'Recently used',
  saturday: 'Saturday',
  saving: 'Saving',
  sunday: 'Sunday',
  supported_files: 'supported files',
  thursday: 'Thursday',
  tuesday: 'Tuesday',
  upload_from_device: 'Upload from your device',
  upload_file_error: 'Media not supported. Try with a different format.',
  videos: 'Videos',
  wednesday: 'Wednesday',
  youtube_link: 'YouTube link',
};
