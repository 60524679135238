import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ThemeProvider, ToastNotification, ToastNotificationProvider } from '@sendible/design-system';
import { lookup } from '@sendible/localization';
import queryClient from '@sendible/frontendv2/src/data-layer/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import PhotoEditor from '@sendible/frontendv2/src/components/CreativeEditor';
import { isAutomationEnvironment } from '@sendible/common';
import CoreFacade from './CoreFacade';
import setupFaroErrorReporting from './faroErrorReporting';

const Reconnections = lazy(() => import('@sendible/reconnections'));
const Signup = lazy(() => import('@sendible/signup'));

window.lookup = lookup;

function Orchestrator() {
  const [theme, setTheme] = useState<string | undefined>(undefined);

  const normalizeTheme = (t: string) => {
    const lc = t.toLowerCase();
    const normalizeUnderscore = lc.split('_').reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
    const normalizedThemeName = normalizeUnderscore.split('-').reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));

    setTheme(normalizedThemeName);
  };

  useEffect(() => {
    if (!isAutomationEnvironment() && window?.$current_user?.id !== undefined) {
      setupFaroErrorReporting(String(window.$current_user.id));
    }
  }, [window?.$current_user?.id]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div />}>
          <ToastNotificationProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/editor/*"
                  element={
                    <Suspense fallback={<div />}>
                      <ToastNotification />
                      <PhotoEditor />
                    </Suspense>
                  }
                />
                <Route
                  path="/reconnections/*"
                  element={
                    <Suspense fallback={<div />}>
                      <Reconnections />
                    </Suspense>
                  }
                />
                <Route
                  path="/signup/*"
                  element={
                    <Suspense fallback={<div />}>
                      <Signup />
                    </Suspense>
                  }
                />
                <Route
                  path="/concise/*"
                  element={<CoreFacade onTheme={(t) => normalizeTheme(t)} />}
                />
                <Route
                  path="*"
                  element={<CoreFacade onTheme={(t) => normalizeTheme(t)} />}
                />
              </Routes>
            </BrowserRouter>
          </ToastNotificationProvider>
        </Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default Orchestrator;
