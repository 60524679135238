import { Component, ReactNode } from 'react';
import { errorUtils } from '@sendible/common';
import { ErrorState } from '../ErrorState';

interface Props {
  children?: ReactNode;
  translationNamespace: string;
}

interface State {
  hasError: boolean;
  error?: DLAPIErrorType;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.onTryAgain = this.onTryAgain.bind(this);
  }

  public componentDidCatch(error: Error) {
    errorUtils.sendErrorDirectlyToOnerror(error.message);
    window.dispatchEvent(new CustomEvent('reportErrorToFaro', { detail: { error } }));
  }

  public static getDerivedStateFromError(error: Error): State {
    let apiError;

    try {
      apiError = JSON.parse(error?.message)?.error;
    } catch (e) {
      apiError = error;
    }

    return {
      hasError: true,
      error: apiError,
    };
  }

  onTryAgain() {
    this.setState({
      hasError: false,
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorState
          errorMessage={this.state.error?.message || ''}
          translationNamespace={this.props.translationNamespace}
        />
      );
    }

    return this.props.children;
  }
}
