import { fetchWithHeaders, getErrorFromFetchWithHeaders, throwError } from '@sendible/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsUrls } from '../../constants/urls';
import { useUrlWithCredentials } from '../../data-layer/useUrlWithCredentials';
import { useQueryWithAccessToken } from '../../data-layer/useReactQueryWithAccessToken';
import { weekDaysArray } from '../../pages/Reports/consts';
import { pendoTikTokReport } from '../../pages/Reports/pendoEvents';
import { useTikTokReportContext } from '../../pages/Reports/tiktok/context';
import { findMedian, weekdayOrder } from '../../utils';

export type PostModel = {
  comments: number;
  likes: number;
  message: string;
  postPicture: string;
  profileName: string;
  profilePicture: string;
  socialNetwork: string;
  uid: string;
  dateMessage: string;
};

const tikTokReportsQueryKey = ReportsUrls.tiktok;

export const useCampaignsReportsModel = () => {
  const getUrlWithCredentials = useUrlWithCredentials();
  const quickReports = async () => {
    try {
      const url = getUrlWithCredentials(ReportsUrls.quickReports);

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getCampaignAudienceGrowth = async (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(ReportsUrls.campaignAudienceGrowth)}&campaignId=${campaignId}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      }).then(({ result }) => result);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getReportCampaignOverview = async (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(ReportsUrls.campaignOverview)}&campaignId=${campaignId}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      }).then(({ result }) => result);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getCampaignPosts = async (campaignId: number, page: number, orderBy: string, socialNetwork: string) => {
    try {
      let parameters = `campaignId=${campaignId}&page=${page}`;

      if (orderBy !== '') {
        parameters = `${parameters}&orderBy=${orderBy}`;
      }
      if (socialNetwork !== '') {
        parameters = `${parameters}&socialNetwork=${socialNetwork}`;
      }

      const url = `${getUrlWithCredentials(ReportsUrls.campaignPosts)}&${parameters}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      }).then(({ result }) => result);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  return {
    quickReports,
    getCampaignAudienceGrowth,
    getReportCampaignOverview,
    getCampaignPosts,
  };
};

export const useGetTikTokProfiles = () => {
  const params = {
    service_id: 20848,
  };

  return useQueryWithAccessToken<UseGetTikTokProfilesReturnType>([ReportsUrls.tiktokProfiles, params], { refetchOnWindowFocus: false });
};

export const useGetTikTokOverview = () => {
  const { endDate, startDate, currentAccountObject, userOffset } = useTikTokReportContext();
  const { t } = useTranslation('reports');

  const params = {
    accountId: currentAccountObject?.id,
    start: startDate,
    end: endDate,
    timezoneOffset: userOffset,
    module: 'ActivityOverview',
  };

  return useQueryWithAccessToken<UseGetTikTokOverviewReturnType>(
    [ReportsUrls.tiktok, params],
    {
      select: useCallback((APIResponse) => {
        const profileViewsText = t('profile_views');
        const followersText = t('followers');
        const videoViewsText = t('video_views');
        const interactionsText = t('interactions');

        // Order of keys dictates the order UI components displayed
        const humanReadableLabels = {
          profileViews: profileViewsText,
          followers: followersText,
          videoViews: videoViewsText,
          interactions: interactionsText,
        };

        const tooltips = {
          profileViews: t('tooltip_tiktok_profile_views'),
          followers: t('tooltip_tiktok_followers'),
          videoViews: t('tooltip_tiktok_video_views'),
          interactions: t('tooltip_tiktok_interactions'),
        };

        const { profileImage, ...metricsData } = APIResponse as TikTokOverviewApiType;

        const metrics = Object.keys(humanReadableLabels).map((key, index) => {
          const metricsKeyData = metricsData[key as keyof typeof metricsData];
          const tooltipText = tooltips[key as keyof typeof tooltips];

          return {
            key: index,
            label: humanReadableLabels[key as keyof typeof humanReadableLabels],
            current:
              metricsKeyData?.current.toLocaleString('en', {
                notation: 'compact',
                maximumFractionDigits: 1,
              }) || '-',
            isPositive: metricsKeyData?.percentage ? metricsKeyData.percentage >= 0 : false,
            difference: metricsKeyData?.difference?.toString() || '-',
            percentage: metricsKeyData?.percentage?.toString() || '-',
            previous: metricsKeyData?.previous?.toString() || '-',
            tooltipText,
          };
        });

        window.pendo.track(pendoTikTokReport, {
          date: `${startDate} - ${endDate}`,
          report_id: currentAccountObject?.id,
          profile_name: currentAccountObject?.username,
          user_type: window.$current_user.profile,
        });

        return { metrics, profileImage };
      }, []),
      enabled: !!currentAccountObject,
      refetchOnWindowFocus: false,
    },
    false
  );
};

export const useGetTikTokVideos = () => {
  const { endDate, startDate, currentAccountObject, userOffset } = useTikTokReportContext();
  const { t } = useTranslation('reports');

  const params = {
    accountId: currentAccountObject?.id,
    start: startDate,
    end: endDate,
    timezoneOffset: userOffset,
    module: 'Videos',
  };

  return useQueryWithAccessToken<UseGetTikTokVideosReturnType>(
    [tikTokReportsQueryKey, params],
    {
      select: useCallback((APIResponse) => {
        if (!APIResponse) throwError(t('api_return_error_video'));

        return APIResponse;
      }, []),
      enabled: !!currentAccountObject,
      refetchOnWindowFocus: false,
      refetchInterval: 30000,
    },
    false
  );
};

export const useGetTikTokPerformances = () => {
  const { endDate, startDate, currentAccountObject, userOffset } = useTikTokReportContext();
  const { t } = useTranslation('reports');

  const params = {
    accountId: currentAccountObject?.id,
    start: startDate,
    end: endDate,
    timezoneOffset: userOffset,
    module: 'Performance',
  };

  return useQueryWithAccessToken<UseGetTikTokPerformanceReturnType>(
    [tikTokReportsQueryKey, params],
    {
      select: useCallback((APIResponse) => {
        if (!APIResponse) throwError(t('api_return_error_performance'));

        return {
          interactions: {
            ...APIResponse.interactions,
            interactionsByDay: APIResponse.interactions.interactionsByDay.sort(
              (itemA: TikTokVideoWeeklyViewPerformanceType, itemB: TikTokVideoWeeklyViewPerformanceType) =>
                new Date(itemA.date).getTime() - new Date(itemB.date).getTime()
            ),
          },
          views: {
            ...APIResponse.views,
            viewsByDay: APIResponse.views.viewsByDay.sort(
              (itemA: TikTokVideoDailyViewPerformanceType, itemB: TikTokVideoDailyViewPerformanceType) =>
                new Date(itemA.date).getTime() - new Date(itemB.date).getTime()
            ),
          },
        };
      }, []),
      enabled: !!currentAccountObject,
      refetchOnWindowFocus: false,
    },
    false
  );
};

export const useGetTikTokAudience = () => {
  const { endDate, startDate, currentAccountObject, userOffset } = useTikTokReportContext();

  const { t } = useTranslation('reports');

  const profileViewsText = t('profile_views');
  const followersText = t('followers');
  const netFollowersText = t('net_followers');

  const params = {
    accountId: currentAccountObject?.id,
    start: startDate,
    end: endDate,
    timezoneOffset: userOffset,
    module: 'Audience',
  };

  return useQueryWithAccessToken<TiktokAudienceReturnType>(
    [ReportsUrls.tiktok, params],
    {
      select: useCallback((APIResponse) => {
        if (!APIResponse) throwError(t('api_return_error_audience'));

        const { totals, timeSeriesData } = APIResponse as TiktokAudienceApiType;
        const userFriendlyMetricNames = {
          profileViews: profileViewsText,
          followers: followersText,
          netFollowers: netFollowersText,
        };

        const metrics = Object.keys(totals).filter((key) => key !== 'date');

        const modifiedTimeSeriesData = metrics.map((metric) => ({
          id: userFriendlyMetricNames[metric as keyof typeof userFriendlyMetricNames],
          data: timeSeriesData
            .sort((itemA, itemB) => new Date(itemA.date).getTime() - new Date(itemB.date).getTime())
            .map((item, index: number) => ({
              x: item.date,
              y: item[metric as keyof typeof item],
              key: `${metric}-${item.date}-${index}`,
            })),
        }));

        return { ...APIResponse, audience: { totals, timeSeriesData: modifiedTimeSeriesData } };
      }, []),
      enabled: !!currentAccountObject,
      refetchOnWindowFocus: false,
    },
    false
  );
};

export const useGetTikTokPublishing = () => {
  const { endDate, startDate, currentAccountObject, userOffset } = useTikTokReportContext();

  const { t } = useTranslation('reports');

  const params = {
    accountId: currentAccountObject?.id,
    start: startDate,
    end: endDate,
    timezoneOffset: userOffset,
    module: 'Publishing',
  };

  return useQueryWithAccessToken(
    [ReportsUrls.tiktok, params],
    {
      select: useCallback((data: unknown) => {
        const APIResponse = data as TikTokPublishingApiType;

        if (!APIResponse) throwError(t('api_return_error_publish'));

        const { publishingBehaviour, followersOnline } = APIResponse;

        const weeklyPatternTransformed = publishingBehaviour.posts.map((item, index) => {
          const date = new Date(item.date);

          const engagementMap: Record<number, string> = {
            3: 'High',
            2: 'Medium',
            1: 'Low',
          };

          const colorMap: Record<number, string> = {
            3: '#E10543',
            2: '#000',
            1: '#0075DB',
          };

          const engagement = engagementMap[item.engagementLevel] || 'Low';
          const color = colorMap[item.engagementLevel] || '#0075DB';

          return {
            color,
            engagementLevel: engagement,
            group: weekDaysArray[date.getUTCDay()],
            hour: date.getHours() + date.getMinutes() / 60,
            id: index.toString(),
            timestamp: item.date,
            volume: item.engagement,
          };
        });

        const weeklyPresenceSorted = followersOnline.followersActivity.sort((a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();

          return dateA - dateB;
        });

        const followersData = weeklyPresenceSorted.reduce((acc, entry) => {
          const date = new Date(entry.date);

          const dayOfTheWeek = date.toLocaleString('en-US', { weekday: 'short' });
          const timeOfTheDay = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });

          const day = acc?.find(({ id }) => id === dayOfTheWeek);
          const dayIndex = acc?.findIndex(({ id }) => id === dayOfTheWeek);
          const timeIndex = day?.data.findIndex(({ x }) => x === timeOfTheDay) as number;

          if (!day) {
            acc.push({
              id: dayOfTheWeek,
              data: [
                {
                  x: timeOfTheDay,
                  y: [entry.activeFollowers],
                },
              ],
            });
          } else if (timeIndex === -1) {
            acc[dayIndex].data.push({
              x: timeOfTheDay,
              y: [entry.activeFollowers],
            });
          } else {
            acc[dayIndex].data[timeIndex].y.push(entry.activeFollowers);
          }

          return acc;
        }, [] as TikTokFollowersActivity[]);

        const followersDataTransformed = followersData.map((item, index) => {
          const newDataWithMedianValues = item.data.map(({ x, y }) => {
            return { x, y: findMedian(y) };
          });

          return {
            ...item,
            data: newDataWithMedianValues,
          };
        });

        followersDataTransformed.sort((a, b) => weekdayOrder[a.id] - weekdayOrder[b.id]);

        return {
          publishingBehaviour: {
            ...publishingBehaviour,
            posts: weeklyPatternTransformed,
          },
          followersOnline: {
            ...followersOnline,
            followersActivity: followersDataTransformed,
          },
        };
      }, []),
      enabled: !!currentAccountObject,
      refetchOnWindowFocus: false,
    },
    false
  );
};

export const useGetTikTokDemographics = () => {
  const { t } = useTranslation('reports');
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const { endDate, startDate, currentAccountObject, userOffset } = useTikTokReportContext();

  const params = {
    accountId: currentAccountObject?.id,
    start: startDate,
    end: endDate,
    timezoneOffset: userOffset,
    module: 'Demographics',
  };

  return useQueryWithAccessToken(
    [ReportsUrls.tiktok, params],
    {
      select: useCallback((data) => {
        if (!data) throwError(t('api_return_error_demographic'));

        const { audienceGenders, audienceCountries } = data as TikTokDemographicsApiType;

        const audienceGendersMapped = audienceGenders?.map((item) => {
          const idAndGender = item.gender.includes('ale') ? item.gender : 'Not Specified';

          return { ...item, id: idAndGender, gender: idAndGender, value: item.percentage };
        });

        const audienceCountriesMapped = audienceCountries
          ?.sort((a, b) => b.percentage - a.percentage)
          .map((item, index) => {
            let className = '';

            if (index === 0) {
              className = 'first';
            }

            if (index === 1 || index === 2) {
              className = 'top-three';
            }

            let countryCode = item.country;
            let countryName;

            try {
              countryName = regionNames.of(item.country);
            } catch (error: unknown) {
              countryName = t('other');
              countryCode = 'un';
            }

            return {
              ...item,
              name: countryName,
              countryCode,
              className,
            };
          });

        return {
          audienceGenders: audienceGendersMapped,
          audienceCountries: audienceCountriesMapped,
        } as unknown as UseGetTikTokDemographicsReturnType;
      }, []),
      enabled: !!currentAccountObject,
      refetchOnWindowFocus: false,
    },
    false
  );
};
