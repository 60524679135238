export default {
  activity_overview: 'Activity Overview',
  api_return_error_audience: 'API response was empty when fetching TikTok audience data',
  api_return_error_demographic: 'API response was empty when fetching TikTok demographic data',
  api_return_error_performance: 'API response was empty when fetching TikTok performance data',
  api_return_error_profile: 'API response was empty when fetching TikTok profiles',
  api_return_error_publish: 'API response was empty when fetching TikTok publishing data',
  api_return_error_video: 'API response was empty when fetching TikTok videos data',
  audience_change_title: 'Audience Change',
  audience_chart_audience_legend: 'Audience',
  audience_chart_decreased_by: 'Your audience decreased by',
  audience_chart_growth_by_day: 'Audience Growth By Day',
  audience_chart_increased_by: 'Your audience increased by',
  audience_chart_split: 'Audience Split',
  audience_chart_title: 'Audience Growth',
  audience_by_gender: 'Audience by gender',
  audience_by_country: 'Audience by Country',
  audience_header: 'Audience',
  campaign_overview_title: 'Campaigns Overview',
  campaigns_report: 'Campaigns report',
  compared_to_previous_period: 'Compared to previous period',
  contact_support: 'contact support.',
  default_error_message:
    "We couldn't create your report. Please refresh your browser and try again. If the issue persists after 24 hours, contact our support team.",
  default_error_message_whitelabel:
    "We couldn't create your report. Please refresh your browser and try again. If the issue persists after 24 hours, contact your admin.",
  demographics_header: 'Demographics',
  download_data_analytics_sidebar_link: 'Download data',
  download_report_tooltip: 'Download PDF report',
  empty_report: 'No data available. Check back after your first post has been published.',
  engaging_posts: 'Most engaging posts',
  error_message: 'Unable to load the report.',
  error_if_persists: 'Try again in about an hour.\nIf the issue persists,',
  error_if_persists_wl: 'Try again in about an hour.\nIf the issue persists, contact your admin.',
  facebook_post_engagement_title: 'Facebook Post Engagement',
  facebook_sidebar_link: 'Facebook Page',
  female: 'Female',
  followers: 'Followers',
  followers_online: 'Followers online',
  followers_online_no_data: 'TikTok is experiencing data issues specific to this module, so it is temporarily hidden.',
  generate_report_button: 'Generate Report',
  generic_report_error: 'Unable to load the data. Try refreshing the page.',
  google_analytics_sidebar_link: 'Google Analytics',
  growth: 'Growth',
  instagram_post_engagement_title: 'Instagram Post Engagement',
  instagram_sidebar_link: 'Instagram',
  interactions: 'Interactions',
  lifetime_data: 'Lifetime data',
  lifetime_views: 'Lifetime views across all published videos within the specified period',
  linkedin_post_engagemen_titlet: 'LinkedIn Post Engagement',
  linkedin_sidebar_link: 'LinkedIn',
  median_followers_online_hour: 'Median followers online by hour of the day',
  median_followers_online_day: 'Median followers online by day of the period',
  most_frequent_day: 'Most frequent day',
  most_frequent_time: 'Most frequent time',
  male: 'Male',
  metrics: 'Metrics',
  my_reports_sidebar_link: 'My reports',
  nothing_here_yet: "There's nothing here yet. Once you publish your first post, data will appear with a delay of 24 to 48 hours.",
  no_reports_available: 'No data available. Check back after your first post has been published.',
  not_defined: 'Not defined',
  not_more_data_error_message: 'No more data available.',
  publishing: 'Publishing',
  publishing_behaviour: 'Publishing Behaviour',
  profile_needs_reconnection: 'Your profile is disconnected, this could cause data inaccuracies. Please, reconnect your profile.',
  needs_more_data: 'Data will appear once your account reaches 100 followers with a delay of 24 to 48 hours. Keep posting to grow your audience!',
  net_followers: 'Net Followers',
  net_followers_growth: 'Net followers growth',
  not_specified: 'Not specified',
  other: 'Other',
  performance_title: 'Performance',
  performance_best_engaging_day: 'Best engaging day',
  performance_best_performing_day: 'Best performing day',
  performance_chart1_title: 'Video views by day',
  performance_chart2_title: 'Interactions by day',
  previous_period: 'Previous period',
  profile_views: 'Profile Views',
  report_builder_sidebar_link: 'Report builder',
  report_campaign_sidebar_link: 'Campaigns',
  report_delays: 'There may be a delay of 24 to 48 hours for some displayed data.',
  report_engagement_sidebar_link: 'Engagement',
  report_sidebar_title: 'Reports Hub',
  report_tiktok_sidebar_link: 'TikTok',
  summary: 'Summary',
  since_previous_period: 'Since previous period',
  tiktok_add_profile: 'Add TikTok Profile',
  tiktok_name: 'TikTok',
  tiktok_report: 'TikTok report',
  tooltip_tiktok_profile_views: "Total number of times the TikTok account's profile has been viewed",
  tooltip_tiktok_followers: 'Total number of followers for the TikTok account',
  tooltip_tiktok_video_views: "The total number of times the TikTok account's videos have been viewed",
  tooltip_tiktok_interactions: 'Total likes, comments and shares on the TikTok account’s videos',
  tooltip_audience: 'Total fans and followers generated by campaign posts (only Facebook, Instagram, and LinkedIn, if applicable).',
  tooltip_interactions: 'Total likes and comments on campaign posts (only Facebook, Instagram, and LinkedIn, if applicable).',
  tooltip_posts_sent: 'Total posts published in the campaign across all social networks.',
  total_engagement: 'Total engagement',
  total_views: 'Total views',
  top_posts_title: 'Top Posts',
  total_published_videos: 'Total published videos',
  try_again_button: 'Refresh',
  video_impression_direct_message: 'Direct Message',
  video_impression_follow: 'Follow',
  video_impression_for_you: 'For You',
  video_impression_others: 'Others',
  video_impression_personal_profile: 'Personal Profile',
  video_impression_search: 'Search',
  video_impression_sound: 'Sound',
  video_views: 'Video Views',
  video_views_by_gender: 'Video views by gender',
  videos_error: 'No videos are available at this period. Choose a different timeframe or create a new video.',
  videos_title: 'Videos',
  videos_text_1: 'Show lifetime',
  videos_text_2: 'of published videos within the selected period.',
  we_couldnt_create_your_report: "We couldn't create your report.",
  youtube_sidebar_link: 'YouTube',
};
