export default {
  add_to_compose: 'Add',
  audience: 'Audience',
  audience_input: 'Enter audience or select one',
  audience_input_error: 'Requires at least 3 characters',
  compose_text_textarea_placeholder: 'How can I assist with your copy? Eg. adapt it for Instagram, add hashtags, improve grammar.',
  default_error_message: 'There was an issue with the request to generate your content. Please try again or contact support.',
  dropdown_clear_button: 'Clear',
  facebook: 'Customise for Facebook',
  generate_button: 'Generate',
  generating_button: 'Generating',
  'google-my-business': 'Customise for Google My Business',
  hashtags: 'Add hashtags',
  instagram: 'Customise for Instagram',
  invalid_argument_error_message:
    'This prompt is invalid. Please provide a prompt with at least 8 characters and two or more words to generate a response.',
  lengthen: 'Lengthen',
  linkedin: 'Customise for LinkedIn',
  option_audience_food_enthusiasts: 'Food enthusiasts',
  option_audience_health_and_wellness_enthusiasts: 'Health and wellness enthusiasts',
  option_audience_home_buyers: 'Home buyers',
  option_audience_patients: 'Patients',
  option_audience_retail_shoppers: 'Retail shoppers',
  option_audience_small_business_owner: 'Small business owners',
  option_audience_students: 'Students',
  option_audience_tech_enthusiasts: 'Tech enthusiasts',
  option_audience_travellers: 'Travellers',
  option_tone_funny: 'Funny',
  option_tone_promotional: 'Promotional',
  option_tone_engaging: 'Engaging',
  option_tone_professional: 'Professional',
  option_tone_assertive: 'Assertive',
  option_tone_catchy: 'Catchy',
  option_tone_inspirational: 'Inspirational',
  option_tone_shocking: 'Shocking',
  pinterest: 'Customise for Pinterest',
  prompt_too_long_error_message: "I'd love to help with your copy, but it's too long. Please shorten it to 1000 characters or less for me to assist.",
  regenerate: 'Regenerate',
  replace_compose: 'Replace',
  rephrase: 'Rephrase',
  shorten: 'Shorten',
  spelling: 'Spelling & grammar',
  textarea_placeholder: 'How can I help? Eg. write a post for Instagram about social media marketing',
  third_party_error_message: "We're not able to provide a response right now. Please try again later. If the issue persists, please contact us.",
  tiktok: 'Customise for TikTok',
  tone: 'Tone',
  tooltip_first_topic: 'Generate content ideas and create posts (when the Compose Box is empty)',
  tooltip_fourth_topic: 'Improve spelling and grammar',
  tooltip_important_bottom: 'We recommend all AI results to be checked and verified by a human as they may produce inaccurate data.',
  tooltip_important_top: 'Important Notice: By using the AI Assistant, you agree to our AI policies and terms of use.',
  tooltip_second_topic: 'Optimise existing copy (when the Compose Box contains text)',
  tooltip_third_topic: 'Suggest hashtags based on your copy',
  tooltip_title: 'With the AI-powered Copy Assistant you can:',
  twitter: 'Customise for Twitter',
  threads: 'Customise for Threads',
};
